import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Cidade } from 'src/interfaces/cidade';
import { Especialidade, Medico } from 'src/interfaces/especialidade';
import { GuiaMedicoService } from 'src/services/guia-medico.service';

@Component({
  selector: 'app-lista-medicos',
  templateUrl: './lista-medicos.component.html',
  styleUrls: ['./lista-medicos.component.scss']
})
export class ListaMedicosComponent implements OnInit {

  isSubmitting = false;
  formFilter: FormGroup;
  cities: Cidade[] = [];
  doctors: Medico[] = [];
  specialties: Especialidade[] = [];

  constructor(
    private fb: FormBuilder,
    private _guiaMedicoService: GuiaMedicoService,
    private _snackBar: MatSnackBar
  ) {}

  openToast(message: string) {
    this._snackBar.open(message, 'Ok', {
      duration: 8000,
    });
  }

  ngOnInit() {

    this.formFilter = this.fb.group({
      entidadeId: [{ value: '', disabled: true}, [ Validators.required, ]],
      especialidadeId: [{ value: null, disabled: true }, [ Validators.required, ]]
    });

    this.isSubmitting = true;

    this._guiaMedicoService.getCidades()
    .subscribe(response => {
      
      this.cities = response.Data;
      this.formFilter.get('entidadeId').enable();

      this.isSubmitting = false;

    }, _ => {

      this.openToast('Não foi possível carregar as cidades');
      this.isSubmitting = false;

    });

  }

  eventChangeCity() {

    this.getEspecialidades();

  }

  private getEspecialidades() {
    const cidade = this.formFilter.get('entidadeId').value;

    this.isSubmitting = true;

    this._guiaMedicoService.getEspecialidades(cidade)
    .subscribe(response => {

      this.isSubmitting = false;
      this.specialties = response.Data;
      this.formFilter.get('especialidadeId').enable();

    }, error => {

      this.isSubmitting = false;
      this.openToast('Não foi possível carregar as especialidades desta cidade');

    });

  }

  submitForm() {

    const { invalid, value } = this.formFilter;

    if (invalid) return false;

    this.isSubmitting = true;
    this.doctors = [];


    this._guiaMedicoService.getMedicos(value.entidadeId, value.especialidadeId)
      .subscribe(response => {

        this.isSubmitting = false;
        this.doctors = response.Data;

      }, error => {
        
        this.isSubmitting = false;
        this.openToast(error.error.Message);

      });

  }

}
