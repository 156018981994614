import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DialogHome, HomeComponent } from '../app/pages/home/home.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { ListaMedicosComponent } from './pages/lista-medicos/lista-medicos.component';
import { SimulacaoComponent } from './pages/simulacao/simulacao.component';

export const PAGES = [
  HomeComponent,
  SimulacaoComponent,
  DialogHome,
  ContatoComponent,
  ListaMedicosComponent
];

const routes: Routes = [
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'medicos', component: ListaMedicosComponent
  },
  {
    path: 'contato', component: ContatoComponent
  },
  {
    path: 'simulacao', component: SimulacaoComponent
  },
  { path: '', redirectTo: "/home", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
