import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    public optionsLangague = [
        {
            label: 'Português',
            value: 'pt'
        },
        {
            label: 'English',
            value: 'en'
        },
        {
            label: 'Español',
            value: 'es'
        }
    ];

    constructor(
        private translate: TranslateService
    ) {}

    getLanguageSelected() {

        return sessionStorage.getItem('translateKey') || 'pt'; 

    }
    
    setLanguageSelected(language: 'en' | 'pt' | 'es') {
        this.translate.setDefaultLang(language);
        sessionStorage.setItem('translateKey', language);
    }   

}