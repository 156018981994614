import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SimulacaoService, Plano } from 'src/services/simulacao.service';

interface ResponseCep {
  bairro: string;
  cep: string;
  complemento: string;
  localidade: string;
  logradouro: string;
  siafi: string;
  uf: string;
};
@Component({
  selector: 'app-simulacao',
  templateUrl: './simulacao.component.html',
  styleUrls: ['./simulacao.component.scss']
})
export class SimulacaoComponent implements OnInit {

  dependentesList = [];
  planos: Plano[] = [];
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  dependentesForm: FormGroup;
  addressFormGroup: FormGroup;
  selectPlan = false;
  planoSelecionado: Plano;
  termsSelected = false;
  isSubmitting = false;

  pdfSrc = null;

  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private simulacaoService: SimulacaoService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {

    this.translate.get(['planos']).subscribe(key => {
      this.planos = key.planos;
    });

    this.dependentesForm = this._formBuilder.group({
      'nome-completo': ['', Validators.required],
      'parentesco': ['', Validators.required],
      'data-nascimento': ['', Validators.required],
      'plano-escolhido': [null, Validators.required],
      'CPF': ['', Validators.required]
    });

    this.firstFormGroup = this._formBuilder.group({
      'nome-completo': ['', Validators.required],
      'data-nascimento': ['', Validators.required],
      Sexo: ['', Validators.required],
      'Celular': ['', Validators.required],
      'estado-civil': ['', Validators.required],
      'Email': ['', [Validators.required, Validators.email]],
      'CPF': ['', [Validators.required]]
    });

    this.addressFormGroup = this._formBuilder.group({
      cep: ['', Validators.required],
      Endereco: ['', Validators.required],
      Bairro: ['', Validators.required],
      cidade: ['', Validators.required],
      estado: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: ['']        
    });


  }

  addDependente() {

    const { value, invalid } = this.dependentesForm;

    if (invalid) return false;

    value['data-nascimento'] = this.formatDate(value['data-nascimento']);

    this.dependentesList.push(value);

    this.dependentesForm.reset();

  }

  getDataCep() {

    const cep = this.addressFormGroup.get('cep').value;
    const url = `https://viacep.com.br/ws/${cep}/json/`;

    let header = new HttpHeaders();
    header.set('Access-Control-Allow-Origin', '*');

    this.http.get(url, { headers: header })
    .pipe(map(res => res))
    .subscribe((responseCep:ResponseCep)  => {

      this.addressFormGroup.patchValue({
        Endereco: responseCep.logradouro,
        estado: responseCep.uf,
        cidade: responseCep.localidade,
        Bairro: responseCep.bairro
      });

    }, error => {

      this.addressFormGroup.patchValue({
        endereco: '',
        estado: '',
        cidade: '',
        bairro: ''
      });

    });

  }

  deleteDependente(index) {

    this.dependentesList.splice(index, 1);

  }

  selecionarPlano(plano: Plano) {

    this.planoSelecionado = plano;

  }

  openToast(message: string) {
    this._snackBar.open(message, 'Ok', {
      duration: 8000,
    });
  }

  submitContrato() {
    this.isSubmitting = true;

    const dataTitular = this.firstFormGroup.value;
    const dataEndereco = this.addressFormGroup.value;

    dataTitular['data-nascimento'] = this.formatDate(dataTitular['data-nascimento']);

    const data = {
      Titular: {
        ...dataTitular,
        ...dataEndereco,
        'plano-escolhido': this.planoSelecionado.id
      },
      Dependentes: this.dependentesList
    };

    

    this.http.post(`${environment.BASE_URL}/simulacao/contrato`, data)
    .pipe(map(res => res))
    .subscribe((response: any)  => {

      this.pdfSrc = response.Data.ContratoHtml;

      this.isSubmitting = false;
      this.planoSelecionado.valor = response.Data.Valor;

    }, error => {

      this.openToast('Ocorreu um problema ao solicitar seu termo');
      this.isSubmitting = false;

    });
  }

  finalizarCompra() {

    this.isSubmitting = true;

    const dataTitular = this.firstFormGroup.value;
    const dataEndereco = this.addressFormGroup.getRawValue();

    console.log(dataTitular);

    dataTitular['data-nascimento'] = this.formatDate(dataTitular['data-nascimento']);

    const data = {
      Titular: {
        ...dataTitular,
        ...dataEndereco
      },
      Dependentes: this.dependentesList,
      'plano-escolhido': this.planoSelecionado.id
    };

    

    this.http.post(`${environment.BASE_URL}/simulacao`, data)
    .pipe(map(res => res))
    .subscribe((response: any)  => {

      this.openToast('Simulação efetuada com sucesso. Entraremos em contato em breve.');
      this.isSubmitting = false;
      setTimeout(() => {

        this.router.navigate(['/']);

      }, 8000);

    }, error => {

      this.openToast('Ocorreu um problema com sua simulação');
      this.isSubmitting = false;

    });

  }

  formatDate(value) {

    const part_value = value.split('-');
    
    return `${part_value[2]}/${part_value[1]}/${part_value[0]}`.replace('undefined/', '').replace('undefined/', '');

  }

  getValueParentesco() {

    const parentesco = this.dependentesForm.get('parentesco').value;

    if (parentesco == 3) {
      this.planos = this.simulacaoService.planos;
    } else {
      this.planos = this.simulacaoService.planos_valores;
    }

  }

}
