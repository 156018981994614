import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/services/language.service';

@Component({
  selector: 'app-menu-component',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  menu = [];
  languages = [];
  languageSelected: string;

  constructor(
    private translate: TranslateService,
    private languagesService: LanguageService
  ) {
    this.languages = languagesService.optionsLangague;
  }

  ngOnInit() {
    this.translate.get('MENU').subscribe(key => this.menu = key);

    this.languageSelected = this.languagesService.getLanguageSelected();

  }

  changeLanguage(language) {

    this.languagesService.setLanguageSelected(language);

  }

}
