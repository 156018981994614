<app-menu-component></app-menu-component>

<section class="section-contact">
  <div class="center">
    <h2 class="title">{{ 'TITLE_CONTACT' | translate  }}</h2>
    <p>{{ 'DESCRIPTION_CONTACT' | translate  }}</p>
    <form action="" class="form-contact" [formGroup]="form" (submit)="submit()">
      <mat-form-field appearance="outline" class="input">
        <mat-label>Nome</mat-label>
        <input matInput formControlName="Nome">
      </mat-form-field>
      <mat-form-field appearance="outline" class="input">
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="Email">
      </mat-form-field>
      <mat-form-field appearance="outline" class="input">
        <mat-label>Celular</mat-label>
        <input matInput formControlName="Celular">
      </mat-form-field>
      <mat-form-field appearance="outline" class="input">
        <mat-label>Assunto</mat-label>
        <mat-select formControlName="Assunto">
          <mat-option disabled>Selecione</mat-option>
          <mat-option value="0">Dúvidas</mat-option>
          <mat-option value="1">Sugestões</mat-option>
          <mat-option value="2">Críticas</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Mensagem</mat-label>
        <textarea style="height: 100px;" matInput formControlName="Mensagem"></textarea>
      </mat-form-field>
      <button class="button" 
        [disabled]="form.invalid" 
        mat-button 
        mat-raised-button 
        color="primary">{{ isSubmiting ? 'Enviando...' : 'Enviar' }}</button>
    </form>
  </div>
</section>

<div class="banner-hero" style="background-image: url(assets/bg-medico.jpeg)">
  <h2 class="title">{{ 'FIND_DOCTOR_TITLE' | translate }}</h2>
  <p>{{ 'FIND_DOCTOR_DESCRIPTION' | translate }}</p>
  <a [routerLink]="['/medicos']"  mat-button mat-raised-button color="primary">
    {{ 'FIND_BUTTON' | translate }}
  </a>
</div>
<footer class="main-footer" id="contato">
  <div class="center">
  <!-- <p [innerHTML]="'FOOTER_WPP' | translate"></p>
  <p [innerHTML]="'FOOTER_NUMBER' | translate"></p> -->
  <div class="socials">
      <a href="https://www.facebook.com/ABMPlanodeVida" target="_blank" class="link-menu">
          <i class="fab fa-facebook-f"></i>
      </a>
      <a href="https://www.instagram.com/abmplanodevida" target="_blank" class="link-menu">
          <i class="fab fa-instagram"></i>
      </a>
  </div>
  </div>
  <div class="legal" [innerHTML]="'LINE_LEGAL' | translate"> </div>
</footer>

<a href="https://api.whatsapp.com/send?phone=5524981237374&text=Clube%20de%20Benef%C3%ADcios%20Premium" target="_blank" class="whatsapp-ico">
  <img src="assets/ico-whatsapp.png" alt="">
</a>