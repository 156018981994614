import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cidade } from 'src/interfaces/cidade';
import { Especialidade, Medico } from 'src/interfaces/especialidade';

interface ResponseGetCities {
    Data: Cidade[], 
    Message: string, 
    Status: string
}

interface ResponseGetSpecialties {
    Data: Especialidade[], 
    Message: string, 
    Status: string
}

interface ResponseGetDoctors {
    Data: Medico[], 
    Message: string, 
    Status: string
}

@Injectable({
    providedIn: 'root'
})
export class GuiaMedicoService {

    constructor(private http: HttpClient) {}

    public getCidades(): Observable<ResponseGetCities> {
        return this.http.get<ResponseGetCities>(`${environment.BASE_API_SITE}/entidades/cidades`);
    }

    public getEspecialidades(entidade): Observable<ResponseGetSpecialties> {
        return this.http.get<ResponseGetSpecialties>(`${environment.BASE_API_SITE}/especialidades/todas?entidadeId=${entidade}`);
    }

    public getMedicos(entidade, especialidade): Observable<ResponseGetDoctors> {
        return this.http.get<ResponseGetDoctors>(`${environment.BASE_API_SITE}/especialidades/medicos?especialidadeId=${especialidade}&entidadeId=${entidade}`);
    }
}
