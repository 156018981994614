import { HttpClient } from '@angular/common/http';
import {Component} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { SimulacaoService } from 'src/services/simulacao.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent {

  form: FormGroup;
  isSubmiting = false;

  constructor(
    private simulacaoService: SimulacaoService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private http: HttpClient
  ) { 


    this.form = this.fb.group({
      Nome: ['', [Validators.required]],
      Email: ['', [Validators.required, Validators.email]],
      Celular: ['', [Validators.required]],
      Assunto: [0, [Validators.required]],
      Tipo: [0, [Validators.required]],
      Mensagem: ['', [Validators.required]],
    })

  }

  openToast(message: string) {
    this._snackBar.open(message, 'Ok', {
      duration: 500,
    });
  }

  submit() {

    if (this.form.invalid) return false;

    this.isSubmiting = true;

    const assuntoValue = parseInt(this.form.get('Assunto').value);

    this.form.patchValue({
      Assunto: assuntoValue
    });

    this.http.post(`${environment.BASE_URL}/contato/enviar`, this.form.value)
    .pipe(map(res => res))
    .subscribe(_  => {

      this.openToast('Contato enviado com sucesso!');
      this.isSubmiting = false;

    }, error => {

      this.openToast('Tivemos problemas ao validar seu contato. Tente novamente');
      this.isSubmiting = false;

    });

  }

}
