import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'premium-site';

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService
  ) {


    const language = this.languageService.getLanguageSelected();

    translate.setDefaultLang(language);
    
  }
  

}
