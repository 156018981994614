<mat-toolbar color="primary" class="menu">
    <div class="center">
        <img src="assets/logo-white.png" class="brand" alt="">
        <nav class="menu-items">
            <a href="#home" class="link-menu">
                <span class="material-icons">
                    home
                </span>
                {{ menu[0] }}
            </a>
            <a [routerLink]="['/contato']" class="link-menu">
              <span class="material-icons">
                  call
              </span>
              {{ menu[1] }}
            </a>
            <a [routerLink]="['/medicos']" class="link-menu">
                <span class="material-icons">
                    medication
                </span>
                {{ menu[2] }}
            </a>
            <div class="socials">
              <a href="https://www.facebook.com/ABMPlanodeVida" target="_blank" class="link-menu">
                  <i class="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.instagram.com/abmplanodevida" target="_blank" class="link-menu">
                  <i class="fab fa-instagram"></i>
              </a>
            </div>
            <!-- <mat-form-field appearance="fill" class="languages">
                <mat-label>{{ 'SELECIONE_IDIOMA' | translate }}</mat-label>
                <select matNativeControl required
                    (ngModelChange)="changeLanguage($event)" 
                    [(ngModel)]="languageSelected">
                    <option *ngFor="let idiom of languages" 
                        [value]="idiom.value">{{ idiom.label }}</option>
                </select>
            </mat-form-field> -->
        </nav>
    </div>
</mat-toolbar>