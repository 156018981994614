import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface Plano {
    tipo: string;
    valor: number;
    opcoes: string[];
    id: number;
}

@Injectable({
    providedIn: 'root'
})
export class SimulacaoService {

    public planos: Plano[] = [];  
    public planos_valores = [];
    
    constructor(
        private translate: TranslateService
    ) {


        this.translate.get(['planos', 'planos_valores']).subscribe((key: any) => {

            this.planos = key.planos;
            this.planos_valores = key.planos_valores;

        });

    }

}
