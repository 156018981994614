import {Component, Inject, OnInit} from '@angular/core';
import { Plano, SimulacaoService } from 'src/services/simulacao.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'dialog-home',
  template: `
  <div  mat-dialog-content>
    <h2>{{ data.titulo }}</h2>
    <p [innerHTML]="data.descricao"></p>
  </div>`,
})
export class DialogHome {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {

  }
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  displayedColumns = ['espec', 'valorsem', 'valorcom'];
  dataSource = [];
  planos: Plano[] = [];
  APRESENTATION_OPTIONS  = [];
  servicos = [];

  constructor(
    private simulacaoService: SimulacaoService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {

    this.translate.get(['APRESENTATION_OPTIONS', 'servicos', 'DATA_SOURCE', 'planos']).subscribe(key => {
      this.APRESENTATION_OPTIONS = key.APRESENTATION_OPTIONS;
      this.servicos = key.servicos;
      this.dataSource = key.DATA_SOURCE;
      this.planos = key.planos;
    });

  }

  openDialog(servico) {

    this.dialog.open(DialogHome, {
      data: servico,
      width: '600px'
    });

  }
  
  ngOnInit() {
  }

}

