<div class="page-simulation">
  <header class="logo">
    <img src="assets/logo-white.png" width="220" alt="">
  </header>
  <div class="container">
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
      <mat-step  [stepControl]="firstFormGroup" [label]="'LABEL_DATA_PERSONAL' | translate">
        <div class="row">
          <div class="container-video">
            <h2>{{ 'SIMULATOR_TITLE' | translate }}</h2>
            <p>{{ 'SIMULATOR_DESCRIPTION' | translate }}</p>
            <video width="100%" height="330" controls>
              <source src="assets/video.mp4" type="video/mp4">
            </video>  
          </div>
          <div class="data-form">
            <form [formGroup]="firstFormGroup">
              <mat-form-field appearance="outline" class="input">
                <mat-label>{{ 'NOME_COMPLETO' | translate }}</mat-label>
                <input matInput formControlName="nome-completo">
              </mat-form-field>
              <mat-form-field appearance="outline" class="input">
                <mat-label>CPF</mat-label>
                <input matInput formControlName="CPF" mask="000.000.000-00">
              </mat-form-field>
              <mat-form-field appearance="outline" class="input">
                <mat-label>{{ 'DATA_NASCIMENTO' | translate }}</mat-label>
                <input matInput type="date"
                  formControlName="data-nascimento">
              </mat-form-field>
              <mat-form-field appearance="outline" class="input">
                <mat-label>{{ 'CELULAR' | translate }}</mat-label>
                <input matInput formControlName="Celular" mask="(00) 0000-00000">
              </mat-form-field>
              <mat-form-field appearance="outline" class="input">
                <mat-label>{{ 'ESTADO_CIVIL' | translate }}</mat-label>
                <mat-select formControlName="estado-civil">
                  <mat-option disabled>Selecione</mat-option>
                  <mat-option value="Solteiro(a)">Solteiro(a)</mat-option>
                  <mat-option value="Casado(a) / União Estável">Casado(a) / União Estável</mat-option>
                  <mat-option value="Divorciado(a)">Divorciado(a)</mat-option>
                  <mat-option value="Viúvo(a)">Viúvo(a)</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="input">
                <mat-label>{{ 'SEXO' | translate }}</mat-label>
                <mat-select formControlName="Sexo">
                  <mat-option disabled>Selecione</mat-option>
                  <mat-option value="Masculino">Masculino</mat-option>
                  <mat-option value="Feminino">Feminino</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="input">
                <mat-label>{{ 'EMAIL' | translate }}</mat-label>
                <input matInput type="email" formControlName="Email">
              </mat-form-field>
              <button class="button" 
                matStepperNext
                [disabled]="firstFormGroup.invalid" 
                mat-button 
                mat-raised-button 
                color="primary">{{ 'CONTINUAR' | translate }}</button>
            </form>
          </div>
        </div>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" [label]="'LABEL_DATA_DEPENDENTES' | translate">
        <div class="row">
          <div class="container-video">
            <h2>{{ 'DEPENDENTES_TITLE' | translate }}</h2>
            <p>{{ 'DEPENDENTES_DESCRIPTION' | translate }}</p>
            <ul class="list-dependentes">
              <li *ngFor="let dependente of dependentesList; let i = index">
                {{ dependente['nome-completo'] }} - {{ dependente.CPF }} <br/>
                <strong>{{ dependente['plano-escolhido'] }}</strong> 
                <button mat-icon-button (click)="deleteDependente(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </li>
            </ul>
            <small *ngIf="!dependentesList.length">{{ 'NONE_DEPENDENTE_ADD' | translate }}</small>
          </div>
          <form class="data-form" [formGroup]="dependentesForm">
            <mat-form-field appearance="outline" class="input">
              <mat-label>{{ 'NOME_COMPLETO' | translate }}</mat-label>
              <input matInput formControlName="nome-completo">
            </mat-form-field>
            <mat-form-field appearance="outline" class="input">
              <mat-label>CPF</mat-label>
              <input matInput formControlName="CPF" mask="000.000.000-00">
            </mat-form-field>
            <mat-form-field appearance="outline" class="input">
              <mat-label>{{ 'PARENTESCO' | translate }}</mat-label>
              <mat-select formControlName="parentesco" (ngModelChange)="getValueParentesco()">
                <mat-option disabled>Selecione</mat-option>
                <mat-option value="1">Filho menor de 21 anos</mat-option>
                <mat-option value="2">Cônjuge</mat-option>
                <mat-option value="3">Outros</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="input">
              <mat-label>{{ 'PLANO' | translate }}</mat-label>
              <mat-select formControlName="plano-escolhido">
                <mat-option disabled>-</mat-option>
                <mat-option *ngFor="let plano of planos" 
                  [value]="plano.tipo">{{ plano.tipo }} {{ plano.valor | currency: 'R$'}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="input">
              <mat-label>{{ 'DATA_NASCIMENTO' | translate }}</mat-label>
              <input matInput type="date" formControlName="data-nascimento">
            </mat-form-field>


            <div class="footer">
              <button class="button" matStepperNext
                (click)="addDependente(); getValueParentesco()" 
                mat-button mat-raised-button color="primary">{{ 'CONTINUAR' | translate }}</button>
              <button 
                [disabled]="dependentesForm.invalid" class="button" 
                (click)="addDependente()" mat-button color="primary">{{ 'ADICIONAR' | translate }}</button>
            </div>
          </form>
        </div>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" [label]="'ESCOLHA_PLANO' | translate">
        <div class="center">
          <ul class="list-plans">
            <li *ngFor="let plano of planos">
              <mat-card class="card-plan">
                <mat-card-header>
                  <mat-card-title>{{ plano.tipo }}</mat-card-title>
                </mat-card-header>
                <h2 class="price">
                  <span>R$</span> {{ plano.valor.toFixed(2) }} <small>/ mês</small>
                </h2>
                <mat-card-content>
                  <ul class="list">
                    <li class="ok" *ngFor="let opcao of plano.opcoes">
                      <span class="material-icons">
                        check_circle
                      </span>
                      {{ opcao }}
                    </li>
                  </ul>
                </mat-card-content>
                <mat-card-actions>
                  <button
                    matStepperNext
                    (click)="selecionarPlano(plano)" 
                    mat-button mat-raised-button color="primary">
                    {{ 'CONTRATE_AGORA' | translate }}</button>
                </mat-card-actions>
              </mat-card>
            </li>
          </ul>
        </div>
      </mat-step>
      <mat-step [stepControl]="addressFormGroup" [label]="'ENDERECO' | translate">
        <div class="center">
          <form class="form-address" [formGroup]="addressFormGroup">
            <mat-form-field appearance="outline" class="input">
              <mat-label>CEP</mat-label>
              <input matInput formControlName="cep" 
                (blur)="getDataCep()" 
                mask="00000-000">
            </mat-form-field>
            <mat-form-field appearance="outline" class="input">
              <mat-label>{{ 'RUA' | translate }}</mat-label>
              <input matInput formControlName="Endereco">
            </mat-form-field>
            <mat-form-field appearance="outline" class="input">
              <mat-label>{{ 'BAIRRO' | translate }}</mat-label>
              <input matInput formControlName="Bairro">
            </mat-form-field>
            <mat-form-field appearance="outline" class="input">
              <mat-label>{{ 'CIDADE' | translate }}</mat-label>
              <input matInput formControlName="cidade">
            </mat-form-field>
            <mat-form-field appearance="outline" class="input">
              <mat-label>{{ 'ESTADO' | translate }}</mat-label>
              <input matInput formControlName="estado">
            </mat-form-field>
            <mat-form-field appearance="outline" class="input">
              <mat-label>{{ 'NUMERO' | translate }}</mat-label>
              <input matInput formControlName="numero">
            </mat-form-field>
            <mat-form-field appearance="outline" class="input">
              <mat-label>{{ 'COMPLEMENTO' | translate }}</mat-label>
              <input matInput formControlName="complemento">
            </mat-form-field>
            <button class="button"
              (click)="submitContrato()" 
              matStepperNext [disabled]="addressFormGroup.invalid" 
              mat-button mat-raised-button color="primary">{{ 'CONTINUAR' | translate }}</button>
          </form>
        </div>
      </mat-step>
      <mat-step [label]="'TERMOS_CONTRATO' | translate">

        <div class="text" *ngIf="!pdfSrc">
          {{ 'AGUARDE' | translate }}
          <mat-spinner [diameter]="20"  color="accent" *ngIf="isSubmitting"></mat-spinner> 
        </div>

        <div class="html-wrapper" *ngIf="pdfSrc">
          <div [innerHtml]="pdfSrc"></div>
        </div>

        <div class="footer-buttons">
          <mat-checkbox [(ngModel)]="termsSelected">{{ 'LI_ACEITO' | translate }}</mat-checkbox>
          <button class="button bt-flex" matStepperNext 
            [disabled]="!termsSelected || !pdfSrc" 
            mat-button
            mat-raised-button 
            color="primary">{{ 'AVANÇAR' | translate }}
          </button>
        </div>
      </mat-step>
      <mat-step [label]="'FINALIZACAO' | translate">
        <header>
          <h1>{{ 'PAGAMENTO_MENSAL' | translate }}</h1> 
        </header>
        <div class="card-payment card-plan">
          <mat-checkbox [(ngModel)]="selectPlan"></mat-checkbox>
          <div class="content">
            <h2 class="price" *ngIf="planoSelecionado">
              <span>R$</span> {{ planoSelecionado.valor.toFixed(2) }}<small>/ mês</small>
            </h2>
            <div class="footer">
              <img src="assets/boleto-bankario.png" alt="">
              <span class="badge"></span>
            </div>
          </div>
        </div>
        <button class="button bt-flex" matStepperNext 
          [disabled]="!selectPlan" 
          mat-button
          (click)="finalizarCompra()" 
          mat-raised-button 
          color="primary">{{ isSubmitting ? 'Finalizando...' : 'Finalizar' }} 
          <mat-spinner [diameter]="38"  color="accent" *ngIf="isSubmitting"></mat-spinner> 
        </button>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>