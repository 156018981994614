<div>
  <app-menu-component></app-menu-component>
  <main class="main-banner" id="beneficios">
      <div class="center">
          <div class="text">
          </div>
      </div>
  </main>
  <section class="apresentation-box">
      <div class="center">
          <div class="content">
              <div class="text">
                  <h4>{{ 'APRESENTATION_TEXT' | translate }}</h4>
                  <a [routerLink]="['/simulacao']" mat-flat-button color="accent">
                    {{ 'BUY_BUTTON' | translate }}
                  </a>
              </div>
              <div class="video">
                <video width="100%" height="300" controls>
                  <source src="assets/video.mp4" type="video/mp4">
                </video>    
              </div>
          </div>
          <ul class="list">
              <li *ngFor="let apresentation of APRESENTATION_OPTIONS; let i = index">
                  <div class="number">{{ i + 1 }}</div>
                  <h4>{{ apresentation.title }}</h4>
                  <p>{{ apresentation.description }}</p>
              </li>
          </ul>
      </div>
  </section>
  <div class="flag-text">
      <h1 [innerHTML]="'TITLE_CARDS' | translate"></h1>
  </div>
  <section class="options-services" id="beneficios">
      <div class="center">
          <ul class="list-services">
              <li *ngFor="let servico of servicos">
                <mat-card class="card">
                    <mat-card-header>
                      <mat-card-title>{{ servico.titulo }}</mat-card-title>
                    </mat-card-header>
                    <img mat-card-image [src]="servico.imagem">
                    <mat-card-content>
                      <p>
                        {{ servico.texto }}
                      </p>
                    </mat-card-content>
                    <mat-card-actions>
                      <button mat-button (click)="openDialog(servico)">
                        {{ 'SAIBA_MAIS' | translate }}
                      </button>
                    </mat-card-actions>
                  </mat-card>
              </li>
          </ul>
      </div>
  </section>
  <div class="flag-text" id="plano">
      <h1 [innerHTML]="'PRICE_TITLE' | translate"></h1>
  </div>
  <section class="table-prices">
      <div class="center">
          <table mat-table [dataSource]="dataSource" class="table">
                  
              <!-- Symbol Column -->
              <ng-container matColumnDef="espec" class="first-column">
                  <th mat-header-cell *matHeaderCellDef>  </th>
                  <td mat-cell *matCellDef="let element"> {{ element.espec }} </td>
              </ng-container>
                  

              <!-- Name Column -->
              <ng-container matColumnDef="valorsem">
                <th mat-header-cell *matHeaderCellDef> Sem o Premium </th>
                <td mat-cell *matCellDef="let element"> {{ element.valorsem  }} </td>
              </ng-container>
            
              <!-- Weight Column -->
              <ng-container matColumnDef="valorcom">
                <th mat-header-cell *matHeaderCellDef> Com o Premium </th>
                <td mat-cell *matCellDef="let element"> <strong>{{element.valorcom }}</strong>  </td>
              </ng-container>
            
              
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>          
      </div>
  </section>
  <section class="partners">
    <div class="center">
      <h2 class="title">{{ 'PARTNERS_TITLE' | translate }}</h2>
      <ul class="list">
        <li>
          <img src="assets/parceiro1.jpeg" alt="">
        </li>
        <li>
          <img src="assets/parceiro2.jpeg" alt="">
        </li>
        <li>
          <img src="assets/parceiro3.jpeg" alt="">
        </li>
        <li>
          <img src="assets/parceiro4.jpeg" alt="">
        </li>
        <li>
          <img src="assets/parceiro5.jpeg" alt="">
        </li>
        <li>
          <img src="assets/parceiro6.jpeg" alt="">
        </li>
        <li>
          <img src="assets/parceiro8.jpeg" alt="">
        </li>
        <!-- <li>
          <img src="assets/parceiro7.jpeg" alt="">
        </li> -->
      </ul>
    </div>
  </section>
  <div class="flag-text" id="planos">
    <h1 [innerHTML]="'PLANS_TITLE' | translate" ></h1>
  </div>
  <div class="container-plans">
    <div class="center">
      <ul class="list-plans">
        <li *ngFor="let plano of planos">
          <mat-card class="card-plan">
            <mat-card-header>
              <mat-card-title>{{ plano.tipo }}</mat-card-title>
            </mat-card-header>
            <h2 class="price">
              <span>R$</span> {{ plano.valor.toFixed(2) }} <small>/ mês</small>
            </h2>
            <mat-card-content>
              <ul class="list">
                <li class="ok" *ngFor="let opcao of plano.opcoes">
                  <span class="material-icons">
                    check_circle
                  </span>
                  {{ opcao }}
                </li>
              </ul>
            </mat-card-content>
            <mat-card-actions>
              <a [routerLink]="['/simulacao']"  mat-button mat-raised-button color="primary">
                {{ 'SIMULAR_AGORA' | translate }}
              </a>
            </mat-card-actions>
          </mat-card>
        </li>
      </ul>
      <div class="legend">
        <p>
          <small>{{ 'LEGEND_PLANS' | translate }}</small>
        </p>
        <p>
          <small>{{ 'LEGEND_PLANS_2' | translate }}</small>
        </p>
      </div>
    </div>
  </div>
  <div class="banner-hero" style="background-image: url(assets/bg-medico.jpeg)">
    <h2 class="title">{{ 'FIND_DOCTOR_TITLE' | translate }}</h2>
    <p>{{ 'FIND_DOCTOR_DESCRIPTION' | translate }}</p>
    <a [routerLink]="['/medicos']"  mat-button mat-raised-button color="primary">
      {{ 'FIND_BUTTON' | translate }}
    </a>
  </div>
  <footer class="main-footer" id="contato">
    <div class="center">
    <!-- <p [innerHTML]="'FOOTER_WPP' | translate"></p>
    <p [innerHTML]="'FOOTER_NUMBER' | translate"></p> -->
    <div class="socials">
        <a href="https://www.facebook.com/ABMPlanodeVida" target="_blank" class="link-menu">
            <i class="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.instagram.com/abmplanodevida" target="_blank" class="link-menu">
            <i class="fab fa-instagram"></i>
        </a>
    </div>
    </div>
    <div class="legal" [innerHTML]="'LINE_LEGAL' | translate"> </div>
  </footer>

  <a href="https://api.whatsapp.com/send?phone=5524981237374&text=Clube%20de%20Benef%C3%ADcios%20Premium" target="_blank" class="whatsapp-ico">
    <img src="assets/ico-whatsapp.png" alt="">
  </a>
</div>