<app-menu-component></app-menu-component>
<div class="banner-hero" style="background-image: url(assets/bg-medico.jpeg)">
    <h2 class="title">{{ 'DOCTOR_TITLE' | translate }}</h2>
     <p>{{ 'DESCRIPTION_CONTACT' | translate }}</p>
</div>
<div class="container">
    <form class="filter-bar" 
        [formGroup]="formFilter" 
        (submit)="submitForm()">
        <div class="block">
            <mat-form-field>
                <mat-label>Selecione a cidade</mat-label>
                <mat-select formControlName="entidadeId" (ngModelChange)="eventChangeCity()">
                  <mat-option disabled>Selecione...</mat-option>
                  <mat-option *ngFor="let city of cities" 
                    [value]="city.EntidadeId">
                    {{ city.Cidade }}
                  </mat-option>
                </mat-select>
            </mat-form-field>           
        </div>
        <div class="block">
            <mat-form-field>
                <mat-label>Selecione a especialidade</mat-label>
                <mat-select formControlName="especialidadeId">
                  <mat-option disabled>Selecione...</mat-option>
                  <mat-option *ngFor="let speciality of specialties" 
                    [value]="speciality.Id">
                      {{ speciality.Nome }}
                  </mat-option>
                </mat-select>
            </mat-form-field>           
        </div>
        <div class="block btn">
            <button 
                [disabled]="formFilter.invalid" 
                mat-raised-button color="primary">Filtrar
            </button>
        </div>
    </form>
    <div class="loading" *ngIf="isSubmitting">
        <mat-spinner [diameter]="60"></mat-spinner>
    </div>
    <ul class="list-doctors">
        <li *ngFor="let doctor of doctors">
            <mat-card class="example-card">
                <mat-card-header>
                    <mat-card-title>{{ doctor.Nome }}</mat-card-title>
                    <mat-card-subtitle>
                        <span class="material-icons">description</span>
                        {{ doctor.CRM }}
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content *ngIf="doctor.Clinicas.length">
                    <div class="container-clinicas">
                        <header>{{ 'CLINICS_TITLE' | translate }}</header>
                        <ul class="list">
                            <li *ngFor="let clinica of doctor.Clinicas">
                                <strong>{{ clinica.Nome }}</strong>
                                <div class="phone">
                                    <span class="material-icons">
                                        phone
                                    </span>
                                    <p *ngIf="clinica.Celular">{{ clinica.Celular }}</p>
                                    <p *ngIf="clinica.Telefone">{{ clinica.Telefone }}</p>
                                </div>
                                <p *ngIf="clinica.Endereco">{{ clinica.Endereco }} </p>
                                <p *ngIf="clinica.UF">{{ clinica.UF }}, {{ clinica.CEP }} </p>
                            </li>
                        </ul>
                    </div>
                </mat-card-content>
            </mat-card>
        </li>
    </ul>
</div>
<footer class="main-footer" id="contato">
    <div class="center">
    <!-- <p [innerHTML]="'FOOTER_WPP' | translate"></p>
    <p [innerHTML]="'FOOTER_NUMBER' | translate"></p> -->
    <div class="socials">
        <a href="https://www.facebook.com/ABMPlanodeVida" target="_blank" class="link-menu">
            <i class="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.instagram.com/abmplanodevida" target="_blank" class="link-menu">
            <i class="fab fa-instagram"></i>
        </a>
    </div>
    </div>
    <div class="legal" [innerHTML]="'LINE_LEGAL' | translate"> </div>
  </footer>
  
  <a href="https://api.whatsapp.com/send?phone=5524981237374&text=Clube%20de%20Benef%C3%ADcios%20Premium" target="_blank" class="whatsapp-ico">
    <img src="assets/ico-whatsapp.png" alt="">
  </a>